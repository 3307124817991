/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-04-19",
    versionChannel: "nightly",
    buildDate: "2023-04-19T00:19:17.744Z",
    commitHash: "4f4327b152087379bae0d566a5d80aa7c9b9c477",
};
